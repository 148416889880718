@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Fira Sans", sans-serif;
  margin: 0;
}
.siteWidth {
  max-width: 1300px;
  margin: 0 auto;
}
a{
  text-decoration: none;
}
#ts{
  color: #e66239;
}
.App {
  background-color: #fdfeff;
}

.App .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0.25rem 1.5rem;
}

.header-container .logo-container img {
  width: 80px;
}

.header-container .contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}

.contact-container .mobile-container {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  gap: 1rem;
}

.mobile-container .icon-mobile img {
  width: 50px;
}

.mobile-container .number-mobile {
  display: flex;
  flex-direction: column;
}

.footer-contact .number-mobile span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.footer-contact .number-mobile span:nth-child(2) {
  font-size: 14px;
  color: #fff;
}

.number-mobile span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  color: #302c4d;
}

.number-mobile span:nth-child(2) {
  font-size: 14px;
  color: #4d4d4d;
}

.App .heading-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.heading-container span {
  width: 80%;
  text-align: center;
  font-weight: 600;
  font-size: 44px;
  color: #3b3665;
}

.App .demo-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App .demo-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  padding: 2rem;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.demo-container .demo-pic img {
  width: 362px;
}

.download-section .download-heading {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 44px;
  font-weight: 700;
}

.download-section .download-heading span:nth-child(1) {
  color: #3b3665;
}

.download-section .download-heading span:nth-child(2) {
  color: #e66239;
}

.download-section .imp-note {
  text-align: left;
  font-weight: 600;
  color: #3a2cbd;
  margin-top: 3rem;
}

.download-section .imp-text {
  text-align: left;
  color: #4d4d4d;
  padding: 1rem 0;
}

.download-section .download-apk-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  padding: 1rem 0.5rem;
}

.download-apk-grid .apk-card {
  width: 280px;
  height: 152px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.apk-card img {
  width: 80px;
  margin: 0 0 10px;
}

.apk-card .apk-download {
  font-weight: 600;
  font-size: 21px;
  color: #0c117b;
  line-height: 25px;
}

.apk-card .apk-mobile {
  font-size: 14px;
  color: #626262;
  line-height: 23px;
}

.apk-card .apk-otp {
  font-size: 14px;
  color: #626262;
  line-height: 23px;
}

.cover-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 0;
}

.cover-section .cover-heading {
  font-size: 44px;
  font-weight: 600;
  color: #3b3665;
  line-height: 36.7px;
}

.cover-section .cover-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  padding: 2rem 0;
}

.cover-card {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.5rem;
  border-radius: 10px;
  width: 280px;
}

.cover-card .cover-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover-card .cover-icon span {
  width: 120px;
  height: 120px;
  /* background-color: #E6623935; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover-card .cover-icon img {
  width: 60px;
  height: 60px;
}

.cover-card .c-heading {
  font-weight: 700;
  color: #3b3665;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  padding: 15px 0 0;
}

.cover-card .c-paragraph {
  font-size: 14px;
  text-align: center;
  padding: 0.5rem;
  color: #626262;
  line-height: 1.6;
}

.gateway-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #4d4d4d05;
  padding: 3rem;
}
.gateway-section .gateway-heading {
  font-size: 44px;
  font-weight: 600;
  color: #3b3665;
  line-height: 36.7px;
  padding: 1rem;
}
.gateway-section .gateway-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.gateway-pic img {
  opacity: 0.4;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
  transition-duration: 0.3s;
}
.gateway-pic img:hover {
  opacity: 0.9;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
  transition-duration: 0.3s;
}
.business-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  padding: 3rem 0;
  align-items: center;
}
.business-right-inner {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  padding: 3rem 0;
}
.business-grid img {
  width: 362px;
}
.business-right-inner .cover-card {
  width: 270px;
}
.footer-section {
  background-color: #8473f1;
  display: flex;
  flex-direction: column;
}
.footer-section .footer-contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 3rem;
}
.footer-contact .logo-container img {
  width: 80px;
}
.footer-contact .contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
}
.footer-copyright {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 3rem;
  border-top: 1px solid #3b366562;
  color: #efefef;
  font-size: 12px;
}
@media screen and (max-width: 420px) {
  #logo-pic {
    width: 100px;
  }

  .header-container .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 0
  }

  .contact-container .mobile-container {
    display: grid;
    grid-template-columns: 25px auto;
    align-items: center;
    gap: 0.25rem;
  }

  .mobile-container .icon-mobile img {
    width: 25px;
  }

  .number-mobile span:nth-child(1) {
    font-size: 12px;
    font-weight: 600;
  }

  .number-mobile span:nth-child(2) {
    font-size: 10px;
  }

  .App .heading-container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .heading-container span {
    width: 90%;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
  }

  .App .demo-container {
    display: grid;
    grid-template-columns: auto;
    gap: 0rem;
    padding: 1rem;
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .download-section .download-heading {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 24px;
    font-weight: 700;
  }

  .download-section .download-apk-grid {
    display: grid;
    gap: 1rem;
    place-content: center;
    padding: 1rem 0.5rem;
    grid-template-columns: auto;
  }

  .demo-container .demo-pic {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .demo-container .demo-pic img {
    width: 332px;
  }

  .cover-section .cover-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.7px;
    text-align: center;
  }

  .cover-section .cover-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    padding: 2rem 0;
  }

  .gateway-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  }

  .gateway-section .gateway-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.7px;
    padding: 1rem;
    text-align: center;
  }

  .gateway-section .gateway-pic {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .gateway-pic img {
    width: 80%;
    opacity: 0.4;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
  }

  .business-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    padding: 3rem 0;
    align-items: center;
    place-content: center;
  }

  .business-grid .business-img {
    text-align: center;
  }

  .business-grid img {
    width: 333px;
  }

  .business-right-inner {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    padding: 1rem 0;
    place-content: center;
  }

  .footer-section .footer-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
  }

  .footer-contact .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    margin-top: 1rem;
  }

  .footer-copyright {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0.5rem 0;
    font-size: 12px;
  }
}

@media (min-width: 421px) and (max-width: 680px) {
  .header-container .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1.5rem;
  }

  .contact-container .mobile-container {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .mobile-container .icon-mobile img {
    width: 35px;
  }

  .number-mobile span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
  }

  .number-mobile span:nth-child(2) {
    font-size: 12px;
  }

  .App .demo-container {
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
    padding: 2rem;
    width: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .cover-section .cover-heading {
    font-size: 44px;
    font-weight: 600;
    line-height: 53.7px;
    text-align: center;
  }

  .cover-section .cover-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 2rem 0;
  }

  .gateway-section .gateway-heading {
    font-size: 44px;
    font-weight: 600;
    color: #3b3665;
    line-height: 53px;
    text-align: center;
    padding: 1rem;
  }

  .gateway-section .gateway-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .gateway-pic img {
    width: 47%;
    opacity: 0.4;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
  }

  .business-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    padding: 3rem 0;
    align-items: center;
    /* place-content: center; */
    text-align: center;
  }

  .business-right-inner {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 3rem 0;
  }

  .footer-section .footer-contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem 3rem;
  }
}
@media (min-width: 681px) and (max-width: 1024px) {
  .header-container .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 1.5rem;
  }

  .contact-container .mobile-container {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .mobile-container .icon-mobile img {
    width: 35px;
  }

  .number-mobile span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
  }

  .number-mobile span:nth-child(2) {
    font-size: 12px;
  }

  .App .demo-container {
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
    padding: 2rem;
    width: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .cover-section .cover-heading {
    font-size: 44px;
    font-weight: 600;
    line-height: 53.7px;
    text-align: center;
  }

  .cover-section .cover-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 2rem 0;
  }

  .gateway-section .gateway-heading {
    font-size: 44px;
    font-weight: 600;
    color: #3b3665;
    line-height: 53px;
    text-align: center;
    padding: 1rem;
  }

  .gateway-section .gateway-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .gateway-pic img {
    width: 47%;
    opacity: 0.4;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
  }

  .business-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    padding: 3rem 0;
    align-items: center;
    /* place-content: center; */
    text-align: center;
  }

  .business-right-inner {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    padding: 3rem 0;
  }

  .footer-section .footer-contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem 3rem;
  }
}